import React from 'react';
import './App.css';

function App() {
  return (
    <div className="container">
      <img src="Bina Logo.png" alt="Bina AI Logo" width="150" />
      {/* <h1>Bina AI</h1> */}
      <p>Your safe AI</p>
      <a href="https://wa.me/message/YE25SWOGMQ5VK1" className="cta-button">Start Here</a>
      <footer>
        <p>Contact us:</p>
        <p>Phone: (201) 416-6018 | Email: info@bina-ai.com</p>
      </footer>
    </div>
  );
}

export default App;
